<template>
  <DashboardTemplate class="feedback" v-loading="btnLoading">
    <Layout class="pd-t-3">
      <div class="bordered pd-x-3 pd-y-2 mg-b-3">
        <h2 class="mg-t-0 text-center">
          ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK (Terms of Service)
          <!-- <br />และ นโยบายความเป็นส่วนตัว (Privacy Policy) -->
        </h2>
        <p class="text-center text-grey">
          (กรุณาอ่านข้อตกลงและเงื่อนไขด้านล่างนี้โดยละเอียด)
        </p>
        <el-divider></el-divider>
        <h4>ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK (Terms of Service)</h4>
        <a target="_blank" :href="pdfLink">รายละเอียด</a>
        <!-- <h4 class="mg-t-1">นโยบายความเป็นส่วนตัว (Privacy Policy)</h4>
        <a target="_blank" :href="privacyLink">รายละเอียด</a> -->
      </div>
      <div>
        <div class="font-20 pd-t-5" style="opacity: 0.7">
          <el-checkbox
            disabled
            v-model="ruleForm.termOfService"
            false-label="0"
            true-label="1"
            class="mg-r-6"
          ></el-checkbox>
          ข้าพเจ้ายอมรับ
          <span class="color-primary">
            ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK (Terms of Service)
            <!-- และ นโยบายความเป็นส่วนตัว (Privacy Policy) -->
          </span>
        </div>
        <!-- <div class="font-20 pd-t-5">
          <el-checkbox
            v-model="ruleForm.consent"
            false-label="false"
            true-label="true"
            class="mg-r-6"
          ></el-checkbox>
          ข้าพเจ้ายินยอม
          <span class="color-primary">
            ที่จะเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อ
            ให้กับหน่วยงานพันธมิตรของ ธสน. ด้านการสนับสนุนทางการเงิน
            เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ เสนอกิจกรรมทางการตลาด
          </span>
        </div> -->
        <!-- <div class="font-20 pd-t-5">
          การเปลี่ยนแปลงความยินยอมข้อมูลส่วนบุคคลมีระยะเวลาดำเนินการ 30 วันทำการ
        </div> -->
      </div>
      <!-- <div class="is-flex js-between mg-t-1">
        <div
          @click="updateconsent()"
          class="text-grey-light hover-secondary cr-pointer"
        >
          ยกเลิก
        </div>

        <el-button
          type="primary"
          class="wid180"
          :loading="btnLoading"
          :disabled="constConcent === ruleForm.consent"
          @click="
            constConcent == 'true' && ruleForm.consent == 'false'
              ? (confirmDialog = true)
              : saveDataConsent()
          "
          >บันทึก
        </el-button>
      </div> -->
      <el-dialog
        v-if="confirmDialog"
        :visible.sync="confirmDialog"
        class="text-center"
        width="40%"
      >
        <h3>ยืนยันการยกเลิกความยินยอม</h3>
        <h4>คุณต้องการยืนยันยกเลิกความยินยอมของคุณหรือไม่</h4>
        <span slot="footer" class="is-flex js-between mg-t-1 pd-x-1">
          <div
            class="text-grey-light hover-secondary cr-pointer"
            @click="confirmDialog = false"
          >
            ยกเลิก
          </div>
          <el-button type="primary" @click="saveDataConsent()"
            >ยืนยัน</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        v-if="successDialog"
        :visible.sync="successDialog"
        class="text-center"
        width="50%"
        @close="directUrl()"
        :destroy-on-close="true"
      >
        <!-- <el-button
          type="success"
          style="cursor: unset"
          class="font-44 mg-y-7 pd-4"
          circle
          ><i class="fas fa-check font-38"></i
        ></el-button> -->
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <h4>
          คุณได้ยกเลิกการยินยอมที่จะเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อ
          ให้กับหน่วยงานพันธมิตรของ ธสน. ด้านการสนับสนุนทางการเงิน
          เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ เสนอกิจกรรมทางการตลาด
        </h4>
        <h2 class="text-primary">
          การเปลี่ยนแปลงความยินยอมข้อมูลส่วนบุคคลมีระยะเวลาดำเนินการ 30 วันทำการ
        </h2>
      </el-dialog>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDashboardTemplate";
import "@/service/Auth";
export default {
  components: {
    DashboardTemplate,
    Layout
  },
  data() {
    return {
      btnLoading: false,
      constConcent: false,
      successDialog: false,
      confirmDialog: false,
      ruleForm: {
        consent: false,
        termOfService: true
      },
      constData: null,
      pdfLink: require("@/assets/terms-of-service.pdf"),
      privacyLink: "https://www.exim.go.th/th/privacypolicy.aspx",
      termLink:
        "https://www.exim.go.th/th/EXIM-SLA/%E0%B8%82%E0%B8%AD%E0%B8%95%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%A3%E0%B8%B0%E0%B8%94%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%AB%E0%B8%9A%E0%B8%A3%E0%B8%81%E0%B8%B2%E0%B8%A3-(1).aspx"
    };
  },
  mounted() {
    this.getUserData();
  },
  beforeDestroy() {
    if (this.user.consent == 0 && this.ruleForm.consent == "false") {
      this.updateconsent();
    }
  },
  methods: {
    async checkSkip ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get("check/skip").catch(e => {
        console.log(e);
      });
      if (typeof res.data.success != "undefined" && res.data.success) {
        this.complacencyStatus = res.data.obj.status;
        if (res.data.obj.status == 0) {
          if (typeof this.$route.query.code != "undefined") {
            this.$router
              .push("/feedback?code=" + this.$route.query.code)
              .catch(err => {});
          } else {
            this.$router.push("/feedback").catch(err => {});
          }
        } else {
          this.directUrl();
        }
      } else {
        if (this.user.recommendStatus <= 1) {
          if (typeof this.$route.query.code != "undefined") {
            this.$router
              .push("/feedback?code=" + this.$route.query.code)
              .catch(err => {});
          } else {
            this.$router.push("/feedback").catch(err => {});
          }
        }
      }
      if (
        typeof this.$route.query.code != "undefined" &&
        this.$route.name == "feedback user"
      ) {
        this.checkReferCode(this.$route.query.code);
      }
    },
    async updateconsent() {
      this.btnLoading = true;
      if (this.user.consent == 0) {
        let obj = {
          consent: false
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.put(`company/consent`, obj).then(async res => {
          this.$store.commit("SET_USER_OBJECT", {
            key: "consent",
            value: 1
          });
          if (
            this.user.recommendStatus == 1 &&
            this.$route.name != "SendSuccess"
          ) {
            this.checkSkip();
          } else {
            this.directUrl();
          }
        });
      } else {
        if (
          this.user.recommendStatus == 1 &&
          this.$route.name != "SendSuccess"
        ) {
          this.checkSkip();
        } else {
          this.directUrl();
        }
      }
    },
    async getUserData() {
      try {
        this.btnLoading = true;
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.get(`/consent`).catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("/api/consent error", e);
          this.btnLoading = false;
        });
        if (res.data.success) {
          // this.constConcent =
          //   res.data.oneTrust[0].transactionType == "ACTIVE" ? "true" : "false";
          // this.ruleForm.consent =
          //   res.data.oneTrust[0].transactionType == "ACTIVE" ? "true" : "false";
          //13/8/2021 แก้ไขดึงจาก Base ของเรา
          this.constConcent =
            res.data.consent.consent == 2 || res.data.consent.consent == 4
              ? "true"
              : "false";
          this.ruleForm.consent =
            res.data.consent.consent == 2 || res.data.consent.consent == 4
              ? "true"
              : "false";
        }
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
        console.log("getUserData error");
      }
    },
    directUrl() {
      this.user != null && this.user.recommendStatus <= 1
        ? this.$router.push("/result")
        : this.$router.push("/factor");
    },
    saveDataConsent() {
      this.btnLoading = true;
      this.confirmDialog = false;
      var val =
        this.ruleForm.consent == "true" || this.ruleForm.consent == true
          ? true
          : false;
      let obj = {
        consent: val
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.put(`company/consent`, obj)
        .then(async result => {
          if (result.data.success) {
            if (this.ruleForm.consent == "true") {
              this.$store.commit("SET_USER_OBJECT", {
                key: "consent",
                value: 4
              });
            }
            if (
              this.constConcent == "true" &&
              this.ruleForm.consent == "false"
            ) {
              this.successDialog = true;
            } else {
              this.directUrl();
              this.$message({
                message: "บันทึกข้อมูลสำเร็จ",
                type: "success"
              });
            }
            // setTimeout(() => {
            //   this.getUserData();
            // }, 3000);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>